<template>
  <div>
    <div class="info-btn">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.info-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 215px;
  margin-top: 32px;
  margin-bottom: 90px;
  .continue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 40px;
    color: #ffffff;
    background: #fd686e;
    border-radius: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
  .skip {
    cursor: pointer;
    display: flex;
    color: #8b8888;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #979797;
  }
}
</style>