<template>
  <div class="work">
    <Card :parent_id="parent_id">
      <p class="ti">基本资料</p>
    </Card>
    <div class="info-fixed">
      <p class="fixed-fo">你的固定资料：</p>
      <div class="info-sketch">
        <p>
          你是一位 <span>{{ sex }} </span> ｜ <span>{{ birthday }}</span> ｜
          <span>{{ maritalStatus }}</span>
          <!-- <span>{{ education }}</span> -->
        </p>
        <div class="xiugai" @click="Infogai">我要修改</div>
        <div v-show="gai" class="gai">
          <div class="xiu">
            <h2>修改途径</h2>
            <i class="icon" @click="Infogai"></i>
          </div>
          <p>
            1、性别/出生日期：
            <span style="color: #fd686eff">前往APP认证身份</span>
            ，系统自动更改；
          </p>
          <p>注：已验证身份证会员无法修改。</p>
          <p>
            2、婚姻状况：<span style="color: #fd686eff"> 前往APP编辑资料页</span
            >上传婚姻证明，审核通过后即可修改。
          </p>
          <p>
            3、身高：<span style="color: #fd686eff">前往APP编辑资料页</span>
            发送体检证明，审核通过后即可修改。
          </p>
          <p>
            4、学历：<span style="color: #fd686eff">前往APP编辑资料页</span>
            发送学历证明，审核通过后即可修改。
          </p>
        </div>
      </div>
    </div>
    <div class="nickname">
      <div class="name">昵称</div>
      <div>
        <div style="width: 273px; height: 40px; display: flex">
          <!-- maxlength="16"  maxlength="8"-->
          <el-input
            v-model="nickName"
            maxlength="8"
            placeholder="请输入你的昵称"
          ></el-input>
          <div style="width:100px;display: flex; align-items: flex-end;" v-if="nicknameAppStatus === '2'">审核中</div>
        </div>
        <p>此项需要审核，且一个月内只能修改5次，审核不通过也会消耗修改次数</p>
      </div>
    </div>

    <div class="nickname">
      <div class="name">手机</div>
      <div>
        <el-input
          v-model="phone"
          maxlength="11"
          disabled
          placeholder="请输入手机号"
        ></el-input>
        <p>
          让你可免费获得网站的征婚进展提醒短信
          <u style="color: #fd686eff; cursor: pointer" @click="xiugai">修改</u>
        </p>
      </div>
      <!-- 弹框01  v-show="Xgphone"-->
      <div class="mongolia" v-if="Xgphone">
        <div class="xg-phone">
          <div class="xiugai-title">
            <p>修改手机号码</p>
            <i class="icon" @click="xiugai"></i>
          </div>
          <!-- 第一步 -->
          <div class="xiugai-first" v-if="XgNext">
            <div class="xg-item">
              <p>当前手机号</p>
              <div class="xg-item-phone">
                <el-input
                  v-model="oldphone"
                  maxlength="11"
                  placeholder="请输入已验证手机号码"
                  @change="inputChange(0)"
                ></el-input>
                <span style="color: red" v-show="y">手机格式错误</span>
              </div>
            </div>
            <div class="xg-item">
              <p>验证码</p>
              <div class="xg-item-yzm">
                <el-input
                  v-model="oldphoneYzm"
                  maxlength="6"
                  placeholder="请输入校验码"
                  @change="inputChange(1)"
                ></el-input>
                <!-- <span style="color: red" v-show="z">验证码错误</span> -->
              </div>
              <div class="yzm">
                <img :src="YzmImg" alt="" />
                <span class="change" @click="huan">看不清,换一张</span>
                <!-- <img
                  src="http://192.168.100.112:8081/system-server/sys/image_code/gen"
                  alt=""
                /> -->
                <i class="ii" v-if="yy">验证码错误</i>
              </div>
              <div class="ss" v-if="ss">当前手机号错误，请检查后重试</div>
            </div>

            <div class="xg-phone-next">
              <p class="next" @click="next" style="cursor: pointer">下一步</p>
            </div>
          </div>
          <!-- 第二部 -->
          <!-- 弹框02 -->
          <div class="xiugai-last" v-if="XgComplete">
            <div class="last-item">
              <p>新手机号</p>
              <!-- @keyup.native="inputChange($event)" @keydown.native="inputChange($event)" -->
              <div class="last-phone">
                <el-input
                  v-model="newphone"
                  maxlength="11"
                  placeholder="请输入手机号码"
                  @change="inputChange(2)"
                ></el-input>
              </div>
              <span
                style="color: red; position: absolute; top: 44px; left: 98px"
                v-show="a"
                >手机格式错误</span
              >
              <div class="yzm-btn">
                <el-button
                  ref="btn"
                  :disabled="disabled"
                  :plain="true"
                  @click="sendcode"
                >
                  {{ btntxt }}
                </el-button>
              </div>
            </div>
            <div class="last-item">
              <p>验证码</p>
              <div class="last-yzm">
                <el-input
                  maxlength="6"
                  v-model="newphoneYzm"
                  placeholder="请输入校验码"
                ></el-input>
              </div>
            </div>
            <p class="tishi" v-if="zciSphone">手机号被注册</p>
            <div class="last-next">
              <p class="components" @click="Component" style="cursor: pointer">
                完成
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nickname">
      <div class="name">工作地区</div>
      <div>
        <Workdata :data1="data1" @workData="work" />
        <p>工作地一个月内只能修改一次</p>
      </div>
    </div>
    <div class="nickname">
      <div class="name">月收入</div>
      <div>
        <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
        <el-select v-model="monthIncome" placeholder="请选择">
          <el-option
            v-for="item in monthIncomes"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <p>此项需要审核，且一个月内只能修改5次，审核不通过也会消耗修改次数</p>
      </div>
    </div>
    <div class="nickname">
      <div class="name">身高</div>
      <div>
        <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
        <el-select v-model="value1" placeholder="请选择">
          <el-option v-for="item in height" :key="item" :value="item">
          </el-option>
        </el-select>
        <p>身高只能修改一次</p>
      </div>
    </div>

    <Btn>
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>



<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import local from "@/api/common/local.js";
import { infocur, infoUpdate } from "@/api/login/login.js";
import Workdata from "@/components/Workdata";
import { Yzm, YzmValid, XiuGphone, compPhone } from "@/api/my/my.js"; //
import { sendSms, subTk, approveUpdate } from "@/api/member/member.js";
import { allAppStatus } from "@/api/member/member.js"; //infoefresh
// {
// 	"account": "15769170134",
// 	"loginMethod": "P",
// 	"loginSource": "PC",
// 	"loginType": "M",
// 	"password": "aaa123123"
// }
export default {
  data() {
    return {
      parent_id: "", //组件
      input: "",
      birthday: "",
      sex: "",
      maritalStatus: "",
      education: "",
      phone: "",
      oldphone: "",
      oldphoneYzm: "",
      newphone: "",
      newphoneYzm: "",
      nickName: "",
      gai: false,
      y: false,
      x: false,
      z: false,
      a: false,
      Xgphone: false,
      XgNext: true,
      XgComplete: false,
      data1: null,
      monthIncomes: [
        {
          value: "3000元以下",
          label: "0",
        },
        {
          value: "3001-5000元",
          label: "1",
        },
        {
          value: "5001-8000元",
          label: "2",
        },
        {
          value: "8001-12000元",
          label: "3",
        },
        {
          value: "12001-20000元",
          label: "4",
        },
        {
          value: "20000-50000元",
          label: "5",
        },
        {
          value: "50000元以上",
          label: "6",
        },
      ],
      monthIncome: "",
      value: "",
      height: [],
      value1: "",
      workregion: "",
      YzmImg: "",
      phoneLL: "",
      yy: false,
      ss: false,
      btntxt: "获取验证码",
      numLL: 10,
      disabled: false,
      zciSphone: false,
      nicknameAppStatus: "3",
    };
  },
  created() {
    this.num();
    this.tkL();
  },
  components: {
    Card,
    Btn,
    Workdata,
  },
  methods: {
    //处理手机号隐私
    mobileFilter(val) {
      let reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, "$1****$2");
    },
    work(data) {
      // console.log("工作地方", data);
      this.workregion = data;
    },
    async num() {
      // 添加数据
      for (let i = 129; i <= 210; i++) {
        this.height.push(i);
      }
      let a = local.get("access_token");

      const { code, data } = await infocur(a);
      if (code == 0) {
        console.log("数据", data);
        // console.log("地方", this.workregion);
        this.birthday = data.user.birthday;
        this.phoneLL = data.user.account;
        this.phone = this.mobileFilter(data.user.phonenumber);
        let a = data.user.sex;
        let b = data.user.maritalStatus;
        let c = data.user.education;
        this.monthIncome = data.user.monthIncome;
        this.value1 = data.user.height;
        this.nickName = data.user.nickName;
        this.parent_id = data.user.id;
        // if (data.user.nickName == null) {
        //   this.nickName = "会员" + data.user.height;
        // } else {
        // }
        this.data1 = {
          workAddrCityId: data.user.workAddrCityId,
          workAddrCityName: data.user.workAddrCityName,
          workAddrDistrictId: data.user.workAddrDistrictId,
          workAddrDistrictName: data.user.workAddrDistrictName,
          workAddrProvinceId: data.user.workAddrProvinceId,
          workAddrProvinceName: data.user.workAddrProvinceName,
        };
        console.log("数据11111111", a);
        if (Number(a) == 0) {
          this.sex = "男士";
        } else if (Number(a) == 1) {
          this.sex = "女士";
        }

        if (b == 0) {
          this.maritalStatus = "未婚";
        } else if (b == 1) {
          this.maritalStatus = "离婚";
        } else if (b == 2) {
          this.maritalStatus = "丧偶";
        }

        if (c == 0) {
          this.education = "高中及以下";
        } else if (c == 1) {
          this.education = "专中";
        } else if (c == 2) {
          this.education = "大专";
        } else if (c == 3) {
          this.education = "大学本科";
        } else if (c == 4) {
          this.education = "硕士";
        } else if (c == 5) {
          this.education = "博士";
        }
      }
      const D = await allAppStatus();
      if (D.code == 0) {
        this.nicknameAppStatus = D.data.nicknameAppStatus;
      }
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    huan() {
      this.YImg();
    },
    BtnName() {
      this.tkL();
      let name = {
        nickName: this.nickName,
      };
      approveUpdate(name);
    },
    BtnJc() {
      this.tkL();
      //保存调接口
      let a = {
        monthIncome: this.monthIncome,
        height: this.value1,
        workAddrCityId: this.workregion.cityId,
        workAddrCityName: this.workregion.city,
        workAddrDistrictId: this.workregion.countyId,
        workAddrDistrictName: this.workregion.county,
        workAddrProvinceId: this.workregion.provinceId,
        workAddrProvinceName: this.workregion.province,
      };
      infoUpdate(a);
      const that = this;
      setTimeout(function () {
        let rel = that.$router.resolve({
          path: "/n/user/_deta",
        });
        window.open(rel.href, "_self");
      }, 1000);
    },
    //修改接口完成
    async bcjx() {
      this.BtnName();
      this.BtnJc();

      this.$message({
        message: "修改成功",
        type: "success",
        offset: 100,
      });
      // this.$router.push({
      //   path: `/n/user/_deta`,
      // });

      // window.location.reload();
    },
    //跳过
    tg() {
      this.$router.push({
        path: `/n/user/_deta`,
      });
    },
    // 倒计时,发短信
    BtnLL() {
      if (this.numLL > 0) {
        this.numLL--;
        this.btntxt = `已发送(${this.numLL}s)`;
        setTimeout(this.BtnLL, 1000);
        this.$refs.btn.$el.style.backgroundColor = "#fafafaff";
        this.$refs.btn.$el.style.color = "#ADADADFF";
      } else {
        this.btntxt = "重新发送";
        this.$refs.btn.$el.style.backgroundColor = "#FD686EFF";
        this.$refs.btn.$el.style.color = "#ffffff";
        this.disabled = false;
      }
    },
    //验证码
    async sendcode() {
      this.numLL = 10;
      this.disabled = true;
      this.BtnLL();
      let a = {
        busType: 4,
        phonenumber: this.newphone,
      };
      const { code, data } = await sendSms(a);
      if (code == 0) {
        console.log("数据", data);
      }
    },
    //完成
    async Component() {
      this.tkL();
      let a = {
        phonenumber: this.newphone,
        validateCode: this.newphoneYzm,
      };
      const { code, data } = await XiuGphone(a);
      if (code == 0) {
        console.log("00", data);
        this.Xgphone = false;
        this.XgNext = !this.XgNext;
        this.XgComplete = false;
        this.tkL();
      } else if (code == 1) {
        this.zciSphone = true;
      }
    },
    async YImg() {
      let a = Date.parse(new Date());
      // process.env.NODE_ENV == "development" ? `http://192.168.100.113:8081/system-server/sys/image_code/gen?t=${a}` :
      this.YzmImg =
        process.env.NODE_ENV == "development"
          ? `http://192.168.100.115:8081/system-server/sys/image_code/gen?t=${a}`
          : `https://api.yezai.love/system-server/sys/image_code/gen?t=${a}`;
      await Yzm(a);
    },
    //修改
    xiugai() {
      this.Xgphone = !this.Xgphone;
      this.y = false;
      this.ss = false;
      this.oldphoneYzm = "";
      this.oldphone = "";
      this.YImg();
      this.oldphone = "";
      this.XgComplete = false;
      this.XgNext = true;
    },
    Infogai() {
      this.gai = !this.gai;
    },
    inputChange(num) {
      //手机号验证最新正则表达式
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (num == 0) {
        if (!reg_tel.test(this.oldphone)) {
          this.y = true;
        } else {
          this.y = false;
        }
      } else if (num == 1) {
        if (this.oldphoneYzm == "") {
          this.z = true;
        } else {
          this.z = false;
        }
      } else if (num == 2) {
        if (!reg_tel.test(this.newphone)) {
          this.a = true;
        } else {
          this.a = false;
        }
      }
    },
    async next() {
      //-------------------------------------------------------
      if (this.oldphone == "") {
        this.y = true;
      } else {
        this.y = false;
      }
      if (this.oldphoneYzm == "") {
        this.yy = true;
      } else {
        this.yy = false;
      }
      if (this.oldphone !== "" && this.oldphoneYzm !== "") {
        let data = {
          phonenumber: this.oldphone,
        };
        const { code } = await compPhone(data);
        if (code == 0) {
          this.ss = false;
          let a = {
            code: this.oldphoneYzm,
          };
          const { code } = await YzmValid(a);
          if (code == 0) {
            this.XgNext = false;
            this.XgComplete = true;
          } else if (code == 1) {
            this.yy = true;
          }
        } else if (code == 1) {
          this.ss = true;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.work {
  // width: 880px;
  // background-color: #ffffff;
  user-select: none;
}
.info {
  display: flex;
  justify-content: space-between;
 
  padding-top: 20px;
  position: relative;
  height: 32px;
  border-bottom: 1px solid #f4f4f4ff;
  user-select: none;
  p {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  p:nth-child(2) {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #fd686e;
    line-height: 20px;
  }
  p::before {
    content: "";
    width: 4px;
    height: 16px;
    background: #fd686e;
    position: absolute;
    top: 23px;
    left: -8px;
  }
}
.info-fixed {
  width: 624px;
  height: 86px;
  background-color: #f5f5f5ff;
  padding: 16px 24px;
  margin-top: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .fixed-fo {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
  }
  .info-sketch {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    position: relative;
    .xiugai {
      cursor: pointer;
      font-size: 16px;
      color: #fd686e;
    }
    .gai {
      position: absolute;
      top: -120px;
      right: -18px;
      z-index: 888;
      background-color: #fffdfd;
      border-radius: 6px;
      border: 1px solid #ffd5d8;
      width: 384px;
      padding: 20px;
      p {
        width: 378px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #757474;
        line-height: 22px;
      }
      .xiu {
        display: flex;
        justify-content: space-between;
        .icon {
          cursor: pointer;
          display: flex;
          background-image: url(../../../assets/images/tubiao/close.d79c63e.gif);
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
        }
        h2 {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c30007;
          line-height: 20px;
        }
      }
    }
  }
}
.name {
  width: 126px;
  padding-left: 24px;
  line-height: 40px;
  font-size: 16px;
  // font-family: PingFangSC-Medium, PingFang SC;
  // font-weight: 500;
  color: #666666;
}
.nickname {
  display: flex;
  margin-top: 16px;
  /deep/ .el-input__inner {
    background-color: #f5f5f5ff;
    border: none;
    outline: none;
  }
  p {
    margin-top: 2px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 17px;
  }
  .mongolia {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .xg-phone {
      z-index: 889;
      width: 480px;
      height: 320px;
      padding: 20px;
      box-sizing: border-box;
      background-color: #ffffffff;
      position: fixed;
      top: 256px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      border-radius: 8px;
      .xiugai-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        border-bottom: 1px solid #ecececff;
        position: relative;
        p {
          width: 390px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }
        .icon {
          cursor: pointer;
          display: flex;
          background-image: url(../../../assets/images/tubiao/close.d79c63e.gif);
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }
      .xiugai-first {
        margin-top: 20px;
        // background-color: #ffffff;
        .xg-item {
          display: flex;
          margin-bottom: 24px;
          position: relative;
          p {
            width: 98px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
          .xg-item-phone {
            width: 240px;
            height: 40px;
            /deep/ .el-input__inner {
              background-color: #f5f5f5ff;
              border: none;
              outline: none;
            }
          }
          .xg-item-yzm {
            width: 124px;
            height: 40px;
            margin-right: 18px;
          }
          .yzm {
            // width: 94px;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;
            .ii {
              position: absolute;
              top: 54px;
              color: red;
            }

            .change {
              font-size: 12px;
              color: #4a90e2;
              cursor: pointer;
              text-decoration: underline;
            }
            img {
              width: 90px;
              margin-right: 10px;
              height: 40px;
            }
          }
          .ss {
            position: absolute;
            top: 70px;
            left: 124px;
            color: red;
          }
        }
        .xg-phone-next {
          display: flex;
          justify-content: center;
          margin-top: 60px;
          .next {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 126px;
            color: #ffffff;
            height: 40px;
            background: #fe676e;
            border-radius: 20px;
          }
        }
      }
      .xiugai-last {
        margin-top: 20px;
        position: relative;
        .last-item {
          display: flex;
          margin-bottom: 24px;
          position: relative;
          p {
            width: 98px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
          .last-phone {
            width: 130px;
            height: 40px;
            margin-right: 12px;
            /deep/ .el-input__inner {
              background-color: #f5f5f5ff;
              border: none;
              outline: none;
            }
          }
          .yzm-btn {
            // width: 102px;
            // height: 40px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // background-color: #fe676eff;
            // color: #ffffff;
            // cursor: pointer;
            // border-radius: 2px;
            /deep/ .el-input {
              width: 180px;
              height: 40px;
            }
            /deep/ .el-input__inner {
              background: #f5f5f5ff;
              background-color: #f5f5f5ff;
              border: none;
              outline: none;
            }
            /deep/ .el-button {
              margin-left: 10px;
              background: #fd686e;
              color: #ffffff;
            }
            /deep/ .el-button.is-plain:hover {
              background: #fd686e;
              border: none;
            }
          }
          .last-yzm {
            width: 240px;
            height: 40px;
            margin-right: 18px;
          }
        }
        .last-next {
          display: flex;
          justify-content: center;
          margin-top: 60px;
          .components {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 126px;
            color: #ffffff;
            height: 40px;
            background: #fe676e;
            border-radius: 20px;
          }
        }
        .tishi {
          position: absolute;
          bottom: 60px;
          left: 40%;
          color: red;
        }
      }
    }
  }
}
.info-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 215px;
  margin-top: 32px;
  margin-bottom: 90px;
  .continue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 40px;
    color: #ffffff;
    background: #fd686e;
    border-radius: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
  .skip {
    cursor: pointer;
    display: flex;
    color: #999;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #979797;
  }
}
</style>