<template>
  <div>
    <div class="info">
      <slot />
      <p @click="Btn">预览资料页</p>
    </div>
  </div>
</template>

<script>
// import local from "@/api/common/local.js";
// import { infocur } from "@/api/login/login.js";
export default {
  props: ["parent_id"],
  data() {
    return {
      idcard: "",
    };
  },
  // created() {
  //   this.c();
  // },
  // mounted:{

  // },
  methods: {
    Btn() {
      // if (this.parent_id) {
      // let a = local.get("access_token");
      // const { code, data } = infocur(a);
      // if (code == 0) {
      //   this.parent_id = data.user.id; //用户id
      //   let rel = this.$router.resolve({
      //     path: "/u",
      //     query: {
      //       // id: this.idcard,
      //       id: this.parent_id,
      //     },
      //   });
      //   window.open(rel.href, "_blank");
      // }
      // this.c();
      // let rel = this.$router.resolve({
      //   path: "/u",
      //   query: {
      //     // id: this.idcard,
      //     id: this.parent_id,
      //   },
      // });
      // window.open(rel.href, "_blank");
      // } else {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          // id: this.idcard,
          id: this.parent_id,
        },
      });
      window.open(rel.href, "_blank");
      // }
    },
    // async c() {
    //   // console.log('tt',this.parent_id)
    //   let a = local.get("access_token");
    //   const { code, data } = await infocur(a);
    //   if (code == 0) {
    //     // this.idcard = data.user.id; //用户id
    //     this.parent_id = data.user.id; //用户id
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  position: relative;
  height: 32px;
  border-bottom: 1px solid #f4f4f4ff;
  .ti {
    font-weight: bold;
    color: #333333;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  p:nth-child(2) {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #fd686e;
    line-height: 20px;
  }
  p::before {
    content: "";
    width: 4px;
    height: 16px;
    background: #fd686e;
    position: absolute;
    top: 23px;
    left: -8px;
  }
}
</style>
