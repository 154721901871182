<!-- 日期搜索框 -->
<template>
  <div id="myDate">
    <div class="m-datepicker">
      <!-- 省份 -->
      <div
        class="b-field-input province-box"
        @mouseenter="enter()"
        @mouseleave="leave()"
      >
        <div class="value default-value" id="a">
          <!-- {{ province == "" ? "省" : province }} -->
          <span v-if="province == ''" class="val-e">省/直辖市</span>
          <!-- <span v-if="province">省</span> -->
          <span v-else >{{ province }}</span>
        </div>
        <div v-show="provinceBtn" class="picker">
          <div class="picker-a">
            <div
              class="province"
              v-for="(item, index) in specialProvinces"
              :key="index"
            >
              <div class="option" @click="bl(item)">
                {{ item.shortName }}
              </div>
            </div>
          </div>
          <div class="picker-b">
            <div
              class="province"
              v-for="(item, index) in provinces"
              :key="index"
            >
              <div class="option" @click="al(item)">
                {{ item.shortName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 市 -->
      <div
        v-show="yincang"
        class="b-field-input city"
        @mouseenter="Menter()"
        @mouseleave="Mleave()"
      >
        <!-- <div class="city-title">
        请先选择省份
      </div> -->
        <div class="value default-value" id="b">
          <!-- {{ city == "" ? "市" : city }} -->
          <span v-if="city == ''" class="val-e">市/区</span>
          <span v-else >{{ city }}</span>
        </div>
        <!-- 日期 -->
        <div class="ci-s" v-show="cityBtn">
          <div class="picker">
            <div
              v-for="(item, index) in Pcitys"
              :key="index"
              class="option"
              @click="ml(item)"
            >
              {{ item.shortName }}
            </div>
          </div>
        </div>
      </div>

      <!-- 县区 -->
      <div class="b-field-input" @mouseenter="Denter()" @mouseleave="Dleave()">
        <div class="value default-value" id="c" v-show="yincang">
          <!-- {{ county == "" ? "县" : county }} -->
          <span v-if="county == ''" class="val-e">区/县</span>
          <span v-else >{{ county }}</span>
        </div>
        <div class="value default-value" id="d" v-show="!yincang">
          <!-- {{ county == "" ? "区" : county }} -->
          <span v-if="county == ''" class="val-e">区/县</span>
          <span v-else >{{ county }}</span>
        </div>
        <!-- 日期 -->
        <div class="days picker daysPicker county-s" v-show="countyBtn">
          <div class="county-num">
            <div
              v-for="item in countys"
              :key="item.name"
              class="option"
              @click="dl(item)"
            >
              {{ item.shortName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { city } from "@/api/my/my.js";
// import local from "@/api/common/local.js"; //存储
import city from "@/assets/js/city.js";
export default {
  props: ["data1", "data2"],
  data() {
    return {
      provinces: [],
      specialProvinces: [],
      Citys: [],
      Pcitys: [],
      countys: [],
      yincang: true,
      monthBtn: false,
      a: false,
      province: "",
      city: "",
      county: "",
      provinceId: null,
      cityId: null,
      countyId: null,
      provinceBtn: false,
      cityBtn: false,
      countyBtn: false,
    };
  },

  created() {
    this.save();
  },
  methods: {
    // 发请求传值
    async save() {
      // if (local.get("city")) {
      //   // console.log("数据");
      // let data = local.get("city");
      let data = city.city;
      for (let i = 0; i < data.length; i++) {
        // 除去北京.天津.上海.重庆.
        if (
          !(
            data[i].shortName == "北京" ||
            data[i].shortName == "天津" ||
            data[i].shortName == "上海" ||
            data[i].shortName == "重庆"
          )
        ) {
          this.provinces.push(data[i]);
        }
        // 数组只有北京.天津.上海.重庆.
        if (
          data[i].id == 110000 ||
          data[i].id == 120000 ||
          data[i].id == 310000 ||
          data[i].id == 500000
        ) {
          this.specialProvinces.push(data[i]);
        }
      }
      // } else {
      //   // console.log("iii");
      //   const { code, data } = await city();
      //   if (code == 0) {
      //     local.set("city", data); //存储数据
      //     for (let i = 0; i < data.length; i++) {
      //       // 除去北京.天津.上海.重庆.
      //       if (
      //         !(
      //           data[i].shortName == "北京" ||
      //           data[i].shortName == "天津" ||
      //           data[i].shortName == "上海" ||
      //           data[i].shortName == "重庆"
      //         )
      //       ) {
      //         this.provinces.push(data[i]);
      //       }
      //       // 数组只有北京.天津.上海.重庆.
      //       if (
      //         data[i].id == 110000 ||
      //         data[i].id == 120000 ||
      //         data[i].id == 310000 ||
      //         data[i].id == 500000
      //       ) {
      //         this.specialProvinces.push(data[i]);
      //       }
      //     }
      //   }
      // }

      // console.log(this.provinces, this.specialProvinces);
    },
    async al(item) {
      this.city = "";
      this.county = "";
      this.yincang = true;
      this.provinceBtn = false;
      this.cityBtn = true;
      this.province = item.shortName;
      this.provinceId = item.id;
      document.querySelector("#a").style.color = "rgb(96, 98, 102)";
      // const { code, data } = await city();
      // if (code == 0) {
      //   console.log(data.length);
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].shortName == item.shortName) {
      //       this.Citys.push(data[i].children);
      //       for (let j = 0; j < this.Citys.length; j++) {
      //         for (let z = 0; z < this.Citys[j].length; z++) {
      //           this.Pcitys.push(this.Citys[j][z]);
      //         }
      //       }
      //     }
      //   }
      // }

      // if (local.get("city")) {
      // let data = local.get("city");
      let data = city.city;
      for (let i = 0; i < data.length; i++) {
        if (data[i].shortName == item.shortName) {
          this.Citys.push(data[i].children);
          for (let j = 0; j < this.Citys.length; j++) {
            for (let z = 0; z < this.Citys[j].length; z++) {
              this.Pcitys.push(this.Citys[j][z]);
            }
          }
        }
      }
      // } else {
      //   const { code, data } = await city();
      //   if (code == 0) {
      //     for (let i = 0; i < data.length; i++) {
      //       if (data[i].shortName == item.shortName) {
      //         this.Citys.push(data[i].children);
      //         for (let j = 0; j < this.Citys.length; j++) {
      //           for (let z = 0; z < this.Citys[j].length; z++) {
      //             this.Pcitys.push(this.Citys[j][z]);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    // 特殊省市处理
    bl(item) {
      this.city = "";
      this.county = "";
      this.countys = [];
      this.countyBtn = true;
      this.yincang = false;
      this.province = item.shortName;
      this.provinceId = item.id;
      this.cityId = item.children[0].id;
      this.city = item.children[0].shortName;
      document.querySelector("#a").style.color = "#000";
      for (let i = 0; i < this.specialProvinces.length; i++) {
        if (this.specialProvinces[i].shortName == item.shortName) {
          let arr = this.specialProvinces[i].children[0].children;
          for (let a = 0; a < arr.length; a++) {
            this.countys.push(arr[a]);
          }
        }
      }
    },

    async ml(item) {
      this.cityBtn = false;
      this.countyBtn = true;
      this.Citys = [];
      this.Pcitys = [];
      this.city = item.shortName;
      this.countys = item.children;
      this.cityId = item.id;
      document.querySelector("#b").style.color = "rgb(96, 98, 102)";
      // console.log("市", this.city);
    },
    dl(item) {
      this.countys = [];
      this.county = item.shortName;
      this.countyBtn = false;
      this.countyId = item.id;
      document.querySelector("#c").style.color = "rgb(96, 98, 102)";
      document.querySelector("#d").style.color = "rgb(96, 98, 102)";
      // console.log("省", this.province);
      // console.log("省编号", this.provinceId);
      // console.log("市", this.city);
      // console.log("市编号", this.cityId);
      // console.log("区", this.county);
      // console.log("县/区编号", this.countyId);
      let data = {
        province: this.province,
        provinceId: this.provinceId,
        city: this.city,
        cityId: this.cityId,
        county: this.county,
        countyId: this.countyId,
      };

      this.$emit("workData", data);
    },
    Menter() {},
    Mleave() {},
    Denter() {},
    Dleave() {},
    enter() {
      if (this.cityBtn || this.countyBtn) {
        this.provinceBtn = false;
      } else {
        this.provinceBtn = true;
      }
    },
    leave() {
      this.provinceBtn = false;
    },
  },
  watch: {
    data1(newValue, oldValue) {
      console.log("新", newValue);
      this.province = newValue.workAddrProvinceName;
      this.city = newValue.workAddrCityName;
      this.county = newValue.workAddrDistrictName;
      console.log("旧", oldValue);
      /**
       * 测试
       */

      if (!newValue.workAddrProvinceName) {
        this.province = "";
        console.log('___');
      } else {
          console.log('___');
        this.province = newValue.workAddrProvinceName;
      }
      if (!newValue.workAddrCityName) {
        this.city = "";
      } else {
        this.city = newValue.workAddrCityName;
      }
      if (!newValue.workAddrDistrictName) {
        this.county = "";
      } else {
        this.county = newValue.workAddrDistrictName;
      }

      /**
       * end
       */

      if (
        newValue.workAddrProvinceName == "北京" ||
        newValue.workAddrProvinceName == "天津" ||
        newValue.workAddrProvinceName == "上海" ||
        newValue.workAddrProvinceName == "重庆"
      ) {
        this.yincang = false;
      }
    },

    data2(newValue, oldValue) {
      // console.log("新", newValue);

      this.province = newValue.nativePlaceProvinceName;
      this.city = newValue.nativePlaceCityName;
      this.county = newValue.nativePlaceDistrictName;
      console.log("旧", oldValue);
      if (newValue.nativePlaceProvinceName == null) {
        this.province = "省";
        // this.yincang = false;
      } else {
        this.province = newValue.nativePlaceProvinceName;
      }
      if (newValue.nativePlaceCityName == null) {
        this.city = "市";
      } else {
        this.city = newValue.nativePlaceCityName;
      }
      if (newValue.nativePlaceDistrictName == null) {
        this.county = "县/区";
      } else {
        this.county = newValue.nativePlaceDistrictName;
      }

      if (
        newValue.nativePlaceProvinceName == "北京" ||
        newValue.nativePlaceProvinceName == "天津" ||
        newValue.nativePlaceProvinceName == "上海" ||
        newValue.nativePlaceProvinceName == "重庆"
      ) {
        this.yincang = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.m-datepicker {
  position: relative;
  display: flex;
}
.m-datepicker .value {
  display: inline-block;
  margin-left: 10px;
  min-width: 90px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  padding: 0 14px;
  background-color: #f4f4f4;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
}
.val-e {
  // color: #666;
      color: rgba(102, 102, 102, 0.4);
}

.m-datepicker .select-value {
  color: #333333;
}
.m-datepicker .title {
  padding-bottom: 10px;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
}
.m-datepicker .picker {
  //   display: flex;
  width: 294px;
  //   flex-wrap: wrap;
  font-size: 16px;
  min-height: 222px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 20;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.3);
  .option {
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  padding: 10px;
  .option:hover {
    background-color: #d9d9d9;
  }
  .picker-a {
    height: 40px;
    border-bottom: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
  }
  .picker-b {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .province {
      margin: 2px;
    }
  }
}

.province-box {
  margin-left: 0px;
  .value {
    margin-left: 0px;
    color: rgb(96, 98, 102);
  }
}
.city {
  position: relative;
  .ci-s {
    display: flex;
    .picker {
      display: flex;
      width: 300px;
      align-items: center;
      flex-wrap: wrap;
      .option {
        margin-right: 10px;
      }
    }
  }
  .city-title {
    position: absolute;
    top: 44px;
    left: 10px;
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: red;
    z-index: 22;
  }
}

.county-s {
  display: flex;
  justify-content: center;

  .county-num {
    display: flex;
    width: 294px;
    flex-wrap: wrap;
    .option {
      min-width: 80px;
    }
  }
}
.option {
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
