import request from "@/api/http.js"



//省市联动城市接口
export const city = (() => {
    return request.post('/system-server/sys/areas/list/recursion')
})

//职业联动
export const dict = ((data) => {
    return request.gettLL(`/system-server/sys/dict/tree`,data)
})


//根据具体数据查位置
export const citys = ((data) => {
    return request.get('/system-server/sys/areas/list', data)
})
//门店信息列表
// export const Information=((data)=>{
//     return request.post('/system-server/sys/shop/list',data)
// })
//根据参数查询会员基础信息列表
export const Information = ((data) => {
    return request.post('/member-server/member/info/page', data)
})

export const Informations = ((data) => {
    return request.post('/member-server/member/info/list', data)
})
// 修改密码
export const passwprd = ((data) => {
    return request.postt('/member-server/member/info/update/pwd', data)
})
// 脱单
// export const Unorder = ((data) => {
//     return request.postt('/member-server/member/info/page/desingle',data)
// })
// //删除浏览记录
// export const Del = ((data) => {
//     return request.postt('/member-server/member/view/del',data)
// })
// 标签分页
export const tag = ((data) => {
    return request.postt('/member-server/member/info/page/flag', data)
})
//标签随机分页
export const tags = ((data) => {
    return request.postt('/system-server/sys/flag/list/random', data)
})

//字母数字验证码
export const Yzm = ((data) => {
    return request.gettL(`/system-server/sys/image_code/gen?t=${data}`)
})
//效验验证码  /sys/image_code/valid
export const YzmValid = ((data) => {
    return request.postt('/system-server/sys/image_code/valid',data)
}) 
//修改手机号 /member/info/update/phone
export const XiuGphone = ((data) => {
    return request.postt('/member-server/member/info/update/phone', data)
})

//人脸识别 /member/info/auth/init
export const authSb = ((data) => {
    return request.gettLL('/member-server/member/info/auth/init',data)
})
export const NONCE = ((data) => {
    return request.gettLL('/member-server/member/info/auth/nonce',data)
})
//人脸回调
export const authBack = ((data) => {
    return request.gettLL('/member-server/member/info/real/ver/cb',data)
})
//验证省份证是否被使用
export const iSidcard = ((data) => {
    return request.postt('/member-server/member/info/vaild/idcard',data)
})
//微信支付接口

//支付结果回调
export const wxpayCallback = ((data) => {
    return request.postt('/finance-server/finance/order/wxpay/callback',data)
})

//添加订单 /finance/order/order/add
export const orderAdd = ((data) => {
    return request.post('/finance-server/finance/order/order/add',data)
})

//创建订单 /finance/order/wxorder/create
export const wxorderCreate = ((data) => {
    return request.post('/finance-server/finance/order/payorder/create',data)
})

//同步订单 /finance/order/order/status/sync
export const statusSync = ((data) => {
    return request.post('/finance-server/finance/order/order/status/sync',data)
})

//生成图片 /finance/order/qrcode/{codeUrl}
export const qrcode = ((data) => {
    return request.gettLL(`/finance-server/finance/order/qrcode/${data}`)
})

//商品类名 /good/list
export const goodList = ((data) => {
    return request.postt('/finance-server/good/list',data)
})

//根据会员ID查询付费会员使用期限
export const midQ = ((data) => {
    return request.post('/member-server/member/info/pay/serve/mid/q',data)
})

//判断是否为当前手机号/member/info/comp/phone
export const compPhone = ((data) => {
    return request.postt('/member-server/member/info/comp/phone',data)
})

//登出接口 /member/logout DELETE
export const logout = ((data) => {
    return request.DELETE('/yezai-auth/member/logout',data)
})

//互相喜欢 /member/love/love
export const loveLove = ((data) => {
    return request.postt('/member-server/member/love/love',data)
})
// 门店地址 /member/shop/list
export const shopList = ((data) => {
    return request.postt('/member-server/member/shop/list',data)
})

// 公司新闻 
export const newsListPage = ((data) => {
    return request.post('/system-server/sys/art/page',data)
})

export const articleDetails = ((data) => {
    return request.gettL(`/system-server/sys/art/info/${data}`)
})

